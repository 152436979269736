.plp {
  display: flex;
  flex-direction: column;
  max-width: 100vw !important;
}
.plp_top_divier {
  display: flex;
  justify-content: space-between;
}
.plp_left_filters {
  display: flex;
  width: 250px;
}
.plp_main_content {
  display: flex;
}
.plp_right_content {
  display: flex;
  flex-wrap: wrap;
}
.plp_product {
  width: 250px;
  min-width: 250px;
  margin: 10px 5px;
}
.plp_right {
  display: flex;
  flex-direction: column;
}
#sortBy_selectDiv {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
#sortBy_selectDiv .ant-select-selection-item {
  text-align: right;
}
.sortBy_selectDiv_text {
  margin-top: 5px;
  font-weight: bolder;
  position: absolute;
  left: 5%;
  z-index: 1;
}
.plp_left_filters {
  display: flex;
  justify-content: space-between;
}
.card_similar_product {
  width: 200px;
  min-width: 200px;
  margin: 10px 10px;
}
.plp_wishlist_div {
  position: absolute;
  top: 0%;
  right: 0%;
  display: none;
  width: 45px;
  height: 35px;
  cursor: default;
  text-align: right;
}
.plp_wishlist {
  margin: 5px 5px;
  font-size: 20px;
  cursor: pointer;
}
.plp_wishlisted {
  margin: 5px 5px;
  font-size: 20px;
  cursor: pointer;
  color: red;
}

.plp_product:hover .plp_wishlist_div {
  display: block;
}
.plp_not_found {
  display: flex;
  width: calc(100vw - 400px);
  min-width: 300px;
  min-height: 200px;
  height: calc(100vh - 200px);
  justify-content: center;
}
