.homePage {
  width: 98vw;
  display: flex;
  flex-direction: column;
}
.site-card-wrapper {
  display: flex;
  flex-direction: column;
  width: 98vw !important;
}
.slick-prev {
  left: 2% !important;
  z-index: 1;
}
.slick-next {
  right: 4% !important;
  z-index: 1;
}
.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
  color: #1890ff !important;
}
