.address-form {
  text-align: left;
}
.profile__address {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.profile_show_address {
  margin: 20px 0px;
}
.ant-form-item-label {
  padding-bottom: 0px !important;
}
.address_input {
  margin-bottom: 0px !important;
}
.profile-address-form-add_address-button {
  margin: 20px 0px 10px 0px;
}
.address_card_button {
  width: 49%;
  color: #40a9ff !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.address_card_divider {
  margin: 10px 0px 0px 0px !important;
}
.address_card_divider_vertical {
  margin: 0px !important;
}
.address_card {
  width: calc(100vw - 250px);
  max-width: 550px;
}
.address_card_fullName {
  width: calc(100vw - 280px);
  max-width: 550px;
}
