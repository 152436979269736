.account {
  display: flex;
}
.account_sidenav {
  margin-top: 20px;
}
.account_content {
  margin: 20px 20px;
  width: calc(100vw - 200px);
  max-width: 600px;
  border: 1px solid #d4d4d9;
  background-color: #ffffff;
  padding: 20px 20px;
  height: fit-content;
}
