.pdp {
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}
.pdp_prod {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.pdp_image {
  margin-top: 20px;
  width: 100vw;
  max-width: 500px;
  overflow: hidden;
}
.pdp_desc {
  margin: 20px 20px;
  text-align: left;
  width: 100vw;
  max-width: 500px;
}
.pdp_desc_rating_heading {
  display: flex;
  justify-content: space-between;
}
.pdp_desc_rating_rating {
  display: flex;
  width: 100%;
  height: 135px;
  padding: 10px 30px;
}
.pdp_desc_rating_rating_left {
  margin-right: 20px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdp_desc_rating_rating_right {
  width: 100vw;
  max-width: 200px;
}
.pdp_rating_count {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a9abb3 !important;
}
.pdp_simi_product {
  height: 250px !important;
}
.rating_rate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 135px;
  align-items: center;
}
